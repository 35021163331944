<template>
  <div class="beneficiary-moral">
    <span class="moral-benef-title"
      >{{ isEdit ? "Modifier" : "Ajouter" }} un bénéficiaire personne
      morale</span
    >
    <div class="giga-fields benef-form">
      <div class="giga-input_field col-lg-12" v-if="!beneficiaryMoral">
        <label class="no-siren-style"
          ><input
            type="checkbox"
            v-model="noSiren"
            @change="handleNoSirenChange"
          />
          Pas de SIREN</label
        >
      </div>
      <div
        :class="beneficiary.siren.$invalid ? 'field-isRequired' : ''"
        class="giga-input_field col-lg-6"
      >
        <label class="input-field_label">SIREN
        <span class="text-danger">*</span>
        </label>
        <div class="input-field_area">
          <input
            type="text"
            id="siren"
            :class="
              beneficiary.siren.$anyDirty && beneficiary.siren.$invalid
                ? 'field-hasError'
                : ''
            "
            @input="debouncedOnSirenChange"
            v-model="beneficiary.siren.$model"
            class="small-input"
            :disabled="isVIEWER || noSiren"
          />
          <div
            class="validation-field-holder"
            v-if="
              (beneficiary.siren.$anyDirty && beneficiary.siren.$invalid) ||
                sirenNotFound
            "
          >
            <small v-if="!beneficiary.siren.required">
              {{ $t("required_field") }}
            </small>
            <small v-if="!beneficiary.siren.pattern">
              {{ $t("siren_invalid") }}
            </small>
            <small v-if="sirenNotFound">
              {{ $t("siret_not_found_in_db") }}
            </small>
          </div>
        </div>
      </div>

      <div
        :class="beneficiary.siret.$invalid ? 'field-isRequired' : ''"
        class="giga-input_field col-lg-6"
      >
        <label class="input-field_label">SIRET
          <span class="text-danger">*</span>
        </label>
        <div class="input-field_area">
          <select
            id="siret"
            :class="
              beneficiary.siret.$anyDirty && beneficiary.siret.$invalid
                ? 'field-hasError'
                : ''
            "
            @change="onSiretChange"
            v-model="beneficiary.siret.$model"
            class="small-input"
            :disabled="isVIEWER || noSiren"
          >
            <option v-for="siret of siretOptions" :key="siret" :value="siret">{{
              siret
            }}</option>
          </select>
          <div
            class="validation-field-holder"
            v-if="beneficiary.siret.$anyDirty && beneficiary.siret.$invalid"
          >
            <small v-if="!beneficiary.siret.required">
              {{ $t("required_field") }}
            </small>
            <small v-if="!beneficiary.siret.pattern">
              {{ $t("siret_invalid_benef") }}
            </small>
          </div>
        </div>
      </div>

      <div
        :class="!beneficiary.addressName.required ? 'field-isRequired' : ''"
        class="giga-input_field col-lg-12"
      >
        <label class="input-field_label">{{ $t("address") }}
          <span class="text-danger">*</span>
        </label>
        <div class="input-field_area">
          <input
            type="text"
            id="addressName"
            :class="
              beneficiary.addressName.$anyDirty &&
              beneficiary.addressName.$invalid
                ? 'field-hasError'
                : ''
            "
            v-model="beneficiary.addressName.$model"
            class="small-input"
            :disabled="isVIEWER"
          />
          <div
            class="validation-field-holder"
            v-if="
              beneficiary.addressName.$anyDirty &&
                beneficiary.addressName.$invalid
            "
          >
            <small v-if="!beneficiary.addressName.required">
              {{ $t("required_field") }}
            </small>
          </div>
        </div>
      </div>

      <div
        :class="!beneficiary.raisonSociale.required ? 'field-isRequired' : ''"
        class="giga-input_field col-lg-3"
      >
        <label class="input-field_label">{{ $t("company_name") }}
          <span class="text-danger">*</span>
        </label>
        <div class="input-field_area">
          <input
            type="text"
            id="raisonSociale"
            :class="
              beneficiary.raisonSociale.$anyDirty &&
              beneficiary.raisonSociale.$invalid
                ? 'field-hasError'
                : ''
            "
            v-model="beneficiary.raisonSociale.$model"
            class="small-input"
            :disabled="isVIEWER"
          />
          <div
            class="validation-field-holder"
            v-if="
              beneficiary.raisonSociale.$anyDirty &&
                beneficiary.raisonSociale.$invalid
            "
          >
            <small v-if="!beneficiary.raisonSociale.required">
              {{ $t("required_field") }}
            </small>
          </div>
        </div>
      </div>

      <div
        :class="!beneficiary.type.required ? 'field-isRequired' : ''"
        class="giga-input_field col-lg-3"
      >
        <label class="input-field_label">{{ $t("beneficiary_type") }}
          <span class="text-danger">*</span>
        </label>
        <div class="input-field_area">
          <select
            id="type"
            :class="
              beneficiary.type.$anyDirty && beneficiary.type.$invalid
                ? 'field-hasError'
                : ''
            "
            v-model="beneficiary.type.$model"
            class="small-input"
            :disabled="isVIEWER"
          >
            <option
              v-for="(label, key) of beneficiaryTypes"
              :key="key"
              :value="key"
              >{{ label }}</option
            >
          </select>
          <div
            class="validation-field-holder"
            v-if="beneficiary.type.$anyDirty && beneficiary.type.$invalid"
          >
            <small v-if="!beneficiary.type.required">
              {{ $t("required_field") }}
            </small>
          </div>
        </div>
      </div>

      <div
        :class="!beneficiary.codePostal.required ? 'field-isRequired' : ''"
        class="giga-input_field col-lg-3"
      >
        <label class="input-field_label">{{ $t("postal_code") }}
          <span class="text-danger">*</span>
        </label>
        <div class="input-field_area">
          <input
            type="text"
            id="codePostal"
            :class="
              beneficiary.codePostal.$anyDirty &&
              beneficiary.codePostal.$invalid
                ? 'field-hasError'
                : ''
            "
            v-model="beneficiary.codePostal.$model"
            @input="getCitiesByZipCode"
            class="small-input"
            :disabled="isVIEWER"
          />
          <div
            class="validation-field-holder"
            v-if="
              beneficiary.codePostal.$anyDirty &&
                beneficiary.codePostal.$invalid
            "
          >
            <small v-if="!beneficiary.codePostal.required">
              {{ $t("required_field") }}
            </small>
          </div>
        </div>
      </div>

      <div
        :class="!beneficiary.nomCommune.required ? 'field-isRequired' : ''"
        class="giga-input_field col-lg-3"
      >
        <label class="input-field_label">{{ $t("city") }}
          <span class="text-danger">*</span>
        </label>
        <div class="input-field_area">
          <select
            id="nomCommune"
            :class="
              beneficiary.nomCommune.$anyDirty &&
              beneficiary.nomCommune.$invalid
                ? 'field-hasError'
                : ''
            "
            v-model="beneficiary.nomCommune.$model"
            class="small-input"
            :disabled="isVIEWER"
          >
            <option
              v-for="city of cities"
              :key="city.id"
              :value="city.nomCommune"
            >
              {{ city.nomCommune }}
            </option>
          </select>
          <div
            class="validation-field-holder"
            v-if="
              beneficiary.nomCommune.$anyDirty &&
                beneficiary.nomCommune.$invalid
            "
          >
            <small v-if="!beneficiary.nomCommune.required">
              {{ $t("required_field") }}
            </small>
          </div>
        </div>
      </div>

      <div
        :class="
          !beneficiary.personneMoralTelephone.required ? 'field-isRequired' : ''
        "
        class="giga-input_field col-lg-6"
      >
        <label class="input-field_label">{{ $t("phone") }}
          <span class="text-danger">*</span>
        </label>
        <div class="input-field_area">
          <input
            type="tel"
            id="personneMoralTelephone"
            :class="
              beneficiary.personneMoralTelephone.$anyDirty &&
              beneficiary.personneMoralTelephone.$invalid
                ? 'field-hasError'
                : ''
            "
            v-model="beneficiary.personneMoralTelephone.$model"
            class="small-input"
            :disabled="isVIEWER"
          />
          <div
            class="validation-field-holder"
            v-if="
              beneficiary.personneMoralTelephone.$anyDirty &&
                beneficiary.personneMoralTelephone.$invalid
            "
          >
            <small v-if="!beneficiary.personneMoralTelephone.required">
              {{ $t("required_field") }}
            </small>
          </div>
        </div>
      </div>
      <div class="giga-input_field col-lg-6"></div>

      <div
        :class="
          !beneficiary.personneMoralEmail.required ? 'field-isRequired' : ''
        "
        class="giga-input_field col-lg-6"
      >
        <label class="input-field_label">{{ $t("email") }}
          <span class="text-danger">*</span>
        </label>
        <div class="input-field_area">
          <input
            type="text"
            id="email"
            :class="
              beneficiary.personneMoralEmail.$anyDirty &&
              beneficiary.personneMoralEmail.$invalid
                ? 'field-hasError'
                : ''
            "
            v-model="beneficiary.personneMoralEmail.$model"
            class="small-input"
            :disabled="isVIEWER"
            @input="debouncedCheckValidityEmail"
          />
          <div
            class="validation-field-holder"
            v-if="
              beneficiary.personneMoralEmail.$anyDirty &&
                beneficiary.personneMoralEmail.$invalid
            "
          >
            <small v-if="!beneficiary.personneMoralEmail.required">
              {{ $t("required_field") }}
            </small>
          </div>
        </div>
      </div>
     <!-- <div class="giga-input_field col-lg-6">
        <label class="input-field_label">{{ $t("status") }}</label>
        <div class="input-field_area">
          <div v-if="emailStatus">
            <div v-if="emailStatus === 'loading'">
              <div class="email-status">
                <img
                  class="icon-status"
                  src="@/assets/images/spinner.svg"
                  alt=""
                />
                <span class="label-status">Vérification en cours</span>
              </div>
            </div>
            <div v-else-if="emailStatus === 'Active'">
              <div class="email-status">
                <img
                  class="custom-icon"
                  src="@/assets/images/check-list.svg"
                  alt=""
                />
                <span class="label-status">Actif</span>
              </div>
            </div>
            <div v-else-if="emailStatus === 'Unspecified'">
              <div class="email-status">
                <img
                  class="custom-icon"
                  src="@/assets/images/alert-notif.svg"
                  alt=""
                />
                <span class="label-status">Non spécifié</span>
              </div>
            </div>
            <div v-else-if="emailStatus === 'Invalid_Syntax'">
              <div class="email-status">
                <img
                  class="custom-icon"
                  src="@/assets/images/alert-notif.svg"
                  alt=""
                />
                <span class="label-status">Syntaxe invalide</span>
              </div>
            </div>
            <div v-else-if="emailStatus === 'Inactive'">
              <div class="email-status">
                <img
                  class="custom-icon"
                  src="@/assets/images/alert-notif.svg"
                  alt=""
                />
                <span class="label-status">Inactif</span>
              </div>
            </div>
          </div>
        </div>
      </div>-->
    </div>
  </div>
</template>

<script>
import { contactsCraftService } from "@/views/Simulations/services/contacts-craft.service";
import { debounce } from "lodash";
import { beneficiaryService } from "@/views/Simulations/services/beneficiary.service";
import { simulationService } from "@/views/Simulations/services";
import { constants } from "@/shared/constants";

export default {
  name: "BeneficiaryMoral",
  data() {
    return {
      emailStatus: "",
      noSiren: false,
      siretOptions: [],
      beneficiaries: [],
      companies: [],
      cities: [],
      beneficiaryTypes: constants.BENEFICIARY_TYPES,
      beneficiaryMoral: null,
      sirenNotFound: false
    };
  },
  created() {
    this.debouncedCheckValidityEmail = debounce(this.checkValidityEmail, 1000);
    this.debouncedOnSirenChange = debounce(this.onSirenChange, 1000);
  },
  mounted() {
    if (this.beneficiary.$model.id) {
      this.beneficiaryMoral = this.beneficiary.$model;
      this.onSirenChange();
      this.getCitiesByZipCode();
    }
  },
  props: ["isVIEWER", "beneficiary", "isEdit"],
  methods: {
    checkValidityEmail() {
      const email = this.beneficiary.personneMoralEmail.$model;
      if (email && !this.beneficiary.personneMoralEmail.$invalid) {
        this.emailStatus = "loading";
        contactsCraftService.getEmailsStatus([email]).then(emails => {
          if (emails.length > 0) {
            this.emailStatus = emails[0].status;
          } else {
            this.emailStatus = undefined;
          }
        });
      }
    },
    handleNoSirenChange() {
      if (this.noSiren) {
        this.siretOptions.push("00000000000000");
        this.beneficiary.siret.$model = "00000000000000";
        this.beneficiary.siren.$model = "000000000";
      } else {
        this.siretOptions = [];
        this.beneficiary.siret.$model = "";
        this.beneficiary.siren.$model = "";
      }
    },
    onSirenChange() {
      const siren = this.beneficiary.siren.$model;
      if (siren && !this.beneficiary.siren.$invalid) {
        beneficiaryService.getSiretsBySiren(siren).then(companies => {
          this.sirenNotFound = companies.length === 0;
          this.companies = companies;
          if (this.beneficiary.siren.$model === "000000000") {
            this.siretOptions.push("00000000000000");
          } else {
            this.siretOptions = companies?.map(company => company.siret);
          }
        });
        beneficiaryService.getBeneficiaryBySiren(siren).then(beneficiaries => {
          this.beneficiaries = beneficiaries;
          this.beneficiaryMoral = beneficiaries[0];
          this.beneficiary.siret.$model = this.beneficiaryMoral?.siret;
          this.onSiretChange();
        });
      }
    },
    onSiretChange() {
      const siret = this.beneficiary.siret.$model;
      const company = this.companies.find(company => company.siret === siret);
      const beneficiary =
        this.beneficiaries.find(
          beneficiary =>
            this.beneficiary?.$model.id === beneficiary.id &&
            siret === beneficiary.siret
        ) ??
        this.beneficiaries.find(beneficiary => siret === beneficiary.siret);
      this.beneficiary.codePostal.$model =
        beneficiary?.basePostalCodes?.codePostal ??
        company?.postalCodeSiret ??
        "";
      this.getCitiesByZipCode();
      this.beneficiary.raisonSociale.$model =
        beneficiary?.raisonSociale ?? company?.raisonSociale ?? "";
      this.beneficiary.addressName.$model =
        beneficiary?.addressName ??
        `${company?.numeroVoie ?? ""} ${company?.typeVoie ??
          ""} ${company?.libelleVoie ?? ""}` ??
        "";
      this.beneficiary.nomCommune.$model =
        beneficiary?.basePostalCodes?.nomCommune?.toUpperCase() ??
        company?.nomCommuneEtablissement?.toUpperCase() ??
        "";
      this.beneficiary.personneMoralTelephone.$model =
        beneficiary?.personneMoralTelephone ?? "";
      this.beneficiary.personneMoralEmail.$model =
        beneficiary?.personneMoralEmail ?? "";
      this.beneficiary.type.$model = beneficiary?.type ?? null;
      this.checkValidityEmail();
    },
    getCitiesByZipCode() {
      const _zipCodeLength = 5;
      if (this.beneficiary.codePostal.$model?.length === _zipCodeLength)
        simulationService
          .fetchCityByZipCode(this.beneficiary.codePostal.$model)
          .then(cities => (this.cities = cities));
    }
  }
};
</script>

<style scoped>
.no-siren-style {
  cursor: pointer !important;
}
.beneficiary-moral {
  padding: 40px;
  border-radius: 6px;
  box-shadow: 0 0 25px 0 rgba(52, 74, 87, 0.05);
  border: solid 1px #c8d4df;
  background-color: #fff;
}
.moral-benef-title {
  font-weight: 500;
  color: #143a5a;
}
.benef-form {
  margin-top: 20px;
}
input {
  background-color: white !important;
}
.email-status {
  display: flex;
  align-items: center;
}
.icon-status {
  width: 10%;
}
.label-status {
  font-size: smaller;
}
.custom-icon {
  width: 8%;
  margin-right: 8px;
}
</style>
